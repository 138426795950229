.about {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6rem 2rem;
    background-color: #f5f7fa; /* Light neutral background */
    color: #1a1f36; /* Dark text for readability */
    font-family: 'Poppins', sans-serif;
  }
  
  .about-content {
    max-width: 600px;
    text-align: left;
  }
  
  .about-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #007bff; /* Accent color */
  }
  
  .about-text {
    font-size: 1.125rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .about-buttons {
    display: flex;
    gap: 1rem;
  }
  
  .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-secondary {
    background-color: transparent;
    border: 2px solid #007bff;
    color: #007bff;
  }
  
  .btn-secondary:hover {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .about-image {
    margin-left: 2rem;
    max-width: 50%;
  }
  
  .about-image img {
    width: 100%;
    max-width: 400px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about {
      flex-direction: column;
      text-align: center;
    }
  
    .about-content {
      text-align: center;
    }
  
    .about-image {
      margin: 2rem 0 0 0;
    }
  }
  