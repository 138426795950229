.projects {
    background-color: #0f172a; /* Dark navy blue */
    color: #ffffff;
    
    font-family: 'Poppins', sans-serif;
  }
  
  .projects-content {
    position: relative;
    max-width: 1500px;
    margin: 0 auto;
    text-align: center;
    padding:40px;
    z-index: 2; /* Places content above the overlay */
  }
  .project-background{
    position: relative;
    background-repeat: no-repeat; /* Ensures the image doesn't repeat */
    background-size:cover; /* Scales the image to cover the container */
    width: 100%; /* Adjust width to container size */
    height: 100%; /* Adjust height to container size or desired dimensions */
   
  }
  .project-background::before {
    content: ""; /* Required for pseudo-elements */
    position: absolute; /* Position it to cover the parent */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% transparency */
    z-index: 1; /* Ensures the overlay is above the background */
    pointer-events: none; /* Prevents the overlay from blocking interactions */
    backdrop-filter: blur(4px);
  }
  .projects-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .projects-intro {
    font-size: 1.125rem;
    color: #d3d3d3; /* Subtle gray for less contrast */
    margin-bottom: 2.5rem;
    line-height: 1.8;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .project-card {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    background: rgba(30, 41, 59,1); /* Slightly lighter background for cards */
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.25);
  }
  
  .project-image { 
   padding:20px;
    object-fit: cover;
    border-bottom: 4px solid #334155;
    max-width: 40%; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the image */
    display: block; /* Ensure proper alignment */
  }
  
  .project-details {
    padding: 1.5rem;
    height: 100%;
  }
  
  .project-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #ffffff;
  }
  
  .project-description {
    font-size: 1rem;
    color: #aab4cc; /* Subtle gray for description text */
    margin-bottom: 1.5rem;
  }
  
  .project-technologies {
    margin-bottom: 1.5rem;
  }
  
  .project-tech {
    background: #334155; /* Dark gray-blue badge */
    color: #94a3b8;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 0.875rem;
    margin: 0.5rem;
    display: inline-block;
  }
  
  .project-links {
    display: flex;
    justify-content: space-between; /* Center the buttons horizontally */
    gap: 1rem; /* Space between the buttons */
    margin-top: auto; /* Push buttons to the bottom */
  }
  
  .project-links .btn {
    flex: 1;
    text-align: center;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-secondary {
    background-color: transparent;
    border: 2px solid #007bff;
    color: #007bff;
  }
  
  .btn-secondary:hover {
    background-color: #007bff;
    color: #ffffff;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .projects-grid {
      grid-template-columns: 1fr;
    }
  
    .project-image {
      height: 150px;
    }
  }
  