@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.btn-Visit{
    background-color: #000000; /* Black for strong contrast */
    padding: 10px;
    color: #ffd700; /* Gold text */
    text-decoration: none;
    border-radius: 5px; /* Optional for better aesthetics */
    font-weight: bold; /* To make text stand out */
    display: inline-block;
    text-align: center;
    cursor: pointer;
}
.btn-website{
    background-color: #000000; /* Black for strong contrast */
    padding: 20px;
    color: #ffd700; /* Gold text */
    text-decoration: none;
    border-radius: 5px; /* Optional for better aesthetics */
    font-weight: bold; /* To make text stand out */
    display: inline-block;
    text-align: center;
    cursor: pointer;
    transition: 0.2s;
}
.btn-website:hover{
    transform: scale(1.05); 
}
.project-content-content {
    position: relative;
    padding: 5rem 2rem;
    font-family: "Poppins", sans-serif;
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Optional parallax effect */
    color: #ffffff;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.project-content-overlay {
    position: relative;
    z-index: 2;
    width: 100%;
    text-align: center;
}

.project-content-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)); /* Gradient overlay for contrast */
    z-index: 1;
}

.project-content-header {
    margin-bottom: 2rem;
    background: rgba(0, 0, 0, 0.75); /* Slightly transparent background */
    border-radius: 15px;
    padding: 2rem 3rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-position: center;
  }
  
  .project-content-header h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #ffffff;
    line-height: 1.2;
  }
  
  .project-content-header p {
    font-size: 1.3rem;
    color: #f0f0f0;
  }

.project-content-description {
    font-size: 1.5rem;
    line-height: 1.8;
    margin-bottom: 2rem;
    color: #eaeaea;
}
.project-content-details {
    background: rgba(0, 0, 0, 0.75); /* Match the header background */
    border-radius: 15px;
    padding: 2rem 3rem;
    margin-top: 3rem; /* Add more space between the sections */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: left; /* Left-align for better readability */
    background-position: center;
  }
  
  .project-content-details h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #ffd700; /* Gold color for headings */
    text-align: center;
  }
  
  .project-content-details p {
    font-size: 1.25rem;
    line-height: 1.8;
    color: #ffffff;
  }

.project-content-products h2 {
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
    color: #f5d700;
}
.project-content-image{
    max-width:70%;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(680px, 2fr)); /* Wider cards for better spacing */
    gap: 2.5rem; /* Increased gap for breathing room */
    margin-top: 1rem;
}

.product-card {
    background: rgba(255, 255, 255, 0.12); /* Slightly brighter semi-transparent background */
    backdrop-filter: blur(8px); /* More blur for stronger glassmorphism effect */
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.4); /* Stronger hover shadow for emphasis */
}

.product-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #ffffff;
}

.product-card img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 1.5rem;
    transition: transform 0.3s ease;
}

.product-card img:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}

.product-card p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #f0f0f0;
}

.product-card strong {
    color: #ffd700; /* Gold for highlights */
}
