/* General Section Styling */
.skills {
    background-color: #0f172a; /* Dark navy blue for a modern look */
    color: #ffffff;
    padding: 4rem 2rem;
    font-family: 'Poppins', sans-serif;
  }
  
  .skills-content {
    max-width: 1500px;
    margin: 0 auto;
    text-align: center;
  }
  
  .skills-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #ffffff;
  }
  
  .skills-intro {
    font-size: 1.125rem;
    color: #94a3b8; /* Subtle gray for less contrast */
    margin-bottom: 2.5rem;
    line-height: 1.8;
  }
  
  /* Grid Layout for Categories */
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  /* Styling for Each Category */
  .skills-category {
    background: #1e293b; /* Slightly lighter background for contrast */
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .skills-category:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.25);
  }
  
  .category-title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #e2e8f0; /* Light gray for contrast */
    text-align: left;
  }
  
  /* Flexbox for Skill Icons and Names */
  .skills-icons {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .skill-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    background: #334155; /* Dark gray-blue for subtle contrast */
    padding: 1rem;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s ease;
    cursor: pointer;
    flex: 1;
    min-width: 120px;
  }
  
  .skill-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .skill-icon {
    font-size: 1.8rem;
    color: #38bdf8; /* Blue accent for icons */
  }
  
  .skill-name {
    font-size: 1rem;
    font-weight: 500;
    color: #f1f5f9; /* Subtle off-white text */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .skills-grid {
      grid-template-columns: 1fr;
    }
  
    .skill-item {
      min-width: 100%;
    }
  }
  