.contact-page {
    background-color: #f8fafc;
    color: #0f172a;
    font-family: "Poppins", sans-serif;
    padding: 4rem 2rem;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .section-intro {
    text-align: center;
    color: #6b7280;
    margin-bottom: 2.5rem;
    font-size: 1.125rem;
    line-height: 1.8;
  }
  
  /* Pricing Section */
  .pricing-cards {
    display: flex;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .pricing-card {
    background: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  }
  
  .pricing-card h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #0f172a;
    margin-bottom: 1rem;
  }
  
  .price {
    font-size: 2rem;
    font-weight: 700;
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .pricing-card ul {
    list-style-type: none;
    padding: 0;
    color: #6b7280;
  }
  
  .pricing-card ul li {
    margin-bottom: 0.5rem;
  }
  
  /* Day Rates Section */
  .day-rates {
    margin-top: 3rem;
    text-align: center;
  }
  
  .day-rate-list {
    list-style: none;
    padding: 0;
    margin: 1.5rem 0;
  }
  
  .day-rate-list li {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
  
  /* Contact Section */
  .contact-section {
    margin-top: 4rem;
    text-align: center;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    text-align: left;
  }
  
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #cbd5e1;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  input:focus,
  textarea:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #ffffff;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }