@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
/* General Hero Section Styles */
/* General Hero Section Styles */
.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6rem 2rem;
    
    color: #ffffff;
    position: relative;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
  }
  .hero::before{
    content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
    background-image: url('../../public/images/heroimage.png');
    filter: blur(10px); /* Apply blur here */
    z-index: 1; /* Keep this behind the content */
  }
  
  .hero-content {
    max-width: 600px;
    text-align: left;
    z-index: 2;
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    color: #aab4cc; /* Light gray for contrast */
  }
  
  .hero-description {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.8;
    margin-bottom: 2.5rem;
    color: #dfe3ee;
  }
  
  .hero-buttons {
    display: flex;
    gap: 1rem;
  }
  
  .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-secondary {
    background-color: transparent;
    border: 2px solid #007bff;
    color: #007bff;
  }
  
  .btn-secondary:hover {
    background-color: #007bff;
    color: #ffffff;
  }
  
  /* Hero Illustration */
  .hero-image {
    margin-left: 2rem;
    max-width: 50%;
    z-index: 1;
  }
  
  .hero-image img {
    width: 100%;
    max-width: 400px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero {
      flex-direction: column;
      text-align: center;
    }
  
    .hero-content {
      text-align: center;
    }
  
    .hero-image {
      margin: 2rem 0 0 0;
    }
  }
  